import { swingbotApi, post } from '../../util/api';

/**
 * getLicenseeCampaignsByLicenseeId
 * - Get all of the campaigns for the licensee
 * @param {int} licenseeId the licensee id
 * @version 2.0
 * @returns 
 */
export const getLicenseeCampaignsByLicenseeId = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * createLicenseeCampaign
 * - create the basic campaign for the licensee
 * 
 * @param {*} licenseeId 
 * @param {*} userId 
 * @param {*} name 
 * @param {*} description 
 * @returns
 * @version 2.0
 */
export const createLicenseeCampaign = (licenseeId, userId, name, description) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns`, { userId, name, description }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

/**
 * duplicateLicenseeCampaign
 * - create a INACTIVE duplicate of the lesson campaign selected
 * - dependent on the licensee plan cap on lessons
 * - check this in the service not in the frontend!
 * @param {*} licenseeCampaignId 
 * @param {*} licenseeId 
 * @param {*} userId 
 * @param {*} status 
 * @returns 
 * @version 2.0
 * 
 */
export const duplicateLicenseeCampaign = (licenseeCampaignId, licenseeId, userId, status) => {
    return swingbotApi(`campaign/${licenseeCampaignId}/duplicate`, { licenseeCampaignId, licenseeId, userId, status }, 'post', true, 'campaign')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

/**
 * updateLicenseeCampaignStatus
 * - update the status (ACTIVE|INACTIVE|ARCHIVED|...) for the campaign
 * 
 * @param {*} licenseeCampaignId 
 * @param {*} licenseeId 
 * @param {*} status ACTIVE|INACTIVE|ARCHIVED
 * @returns {object} return object
 * @version 2.0
 */
export const updateLicenseeCampaignStatus = (licenseeCampaignId, licenseeId, status) => {
    return swingbotApi(`campaign/${licenseeCampaignId}/status`, { licenseeCampaignId, licenseeId, status }, 'put', true, 'campaign')
        .then(response => response)
        .catch(err => Promise.reject(err));
}
// licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, description=""
/**
 * updateLicenseeCampaign
 * @param {*} licenseeId 
 * @param {*} licenseeCampaignId 
 * @param {*} title 
 * @param {*} price 
 * @param {*} licenseeCampaignType 
 * @param {*} status 
 * @param {*} lessonOutput 
 * @param {*} endpoint 
 * @param {*} description 
 * @version 2.0
 * @returns 
 */
export const updateLicenseeCampaign = (licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns/${licenseeCampaignId}`, { 
        title, 
        price,
        description,
        status,
        campaign_type: licenseeCampaignType,
        lesson_output: lessonOutput,
        endpoint
    }, 'put', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
}



export const sendToWebhook = (data, url) => {
    // lets simply send data to the endpoint
    return post(data, url)
        .then(data => data)
        .catch(e => Promise.reject(e));
}
