export const CREATE_TARGET = 'target/create';
export const CREATE_TARGET_SUCCESS = 'target/create-success';
export const CREATE_TARGET_FAIL = 'target/create-fail';

export const RESET_CREATE_TARGET = 'target/reset-create-target';

// export const GET_TARGETS_FOR_USER = 'target/get-targets-for-user';
// export const GET_TARGETS_FOR_USER_SUCCESS = 'target/get-targets-for-user-success';
// export const GET_TARGETS_FOR_USER_FAIL = 'target/get-targets-for-user-fail';

export const GET_TARGET_LIBRARY = 'target/get-target-library';
export const GET_TARGET_LIBRARY_SUCCESS = 'target/get-target-library-success';
export const GET_TARGET_LIBRARY_FAIL = 'target/get-target-library-fail';

export const ADD_TARGET_FROM_LIBRARY = 'target/add-target-from-library';
export const ADD_TARGET_FROM_LIBRARY_SUCCESS = 'target/add-target-from-library-success';
export const ADD_TARGET_FROM_LIBRARY_FAIL = 'target/add-target-from-library-fail';
