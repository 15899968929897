import React from 'react';
import { connect } from 'react-redux';
import ModalTW from '../../common/modal/ModalTW';
import { Button, Loading } from '../../common';
import { getLicenseeDataByUserId } from '../../../actions/licensee';
import { getLicenseeCampaignsByLicenseeId } from '../../../actions/licenseeCampaign';
import { assignCreditToUser, assignCreditToUserReset } from '../../../actions/user';
import { selectors as licenseeCampaignSelectors } from '../../../reducers/licenseeCampaign';
import { selectors as userSelectors } from '../../../reducers/user';

class ModalAssignLessonCredit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      assignLessonSelected: null,
    }
  }

  componentDidMount = () => {
    const { lessons, authUser, licenseeData } = this.props;
    this.props.assignCreditToUserReset();
    // if no lessons, lets get them!
    if (lessons === null) {
      this.props.getLicenseeCampaignsByLicenseeId(authUser.licensee_id);
    }
    if (licenseeData === null) {
      this.props.getLicenseeDataByUserId(authUser.user_id);
    }
  }

  handleAssignCreditConfirm = () => {
    const { assignLessonSelected } = this.state;
    const { userId, licenseeData } = this.props;
    if ((assignLessonSelected !== null && assignLessonSelected !== '') && licenseeData['api_key']) {
      // let's assign the credit to the user
      this.props.assignCreditToUser(userId, assignLessonSelected, licenseeData['api_key']);
    }
  }

  handleChooseLessonProgram = (e) => {
      this.setState({
        assignLessonSelected: e.target.value,
      })
  }

  handleAssignCreditClose = () => {
      this.props.onClose();
  }

  handleAssignCreditComplete = () => {
    this.props.onComplete();
  }

  assignLessonProgramOptions = () => {
    const { lessons } = this.props;
    if (lessons !== null) { 
        return lessons
          .filter(lesson => lesson.licensee_campaign_status === 'ACTIVE')
          .map(lesson => {
            return (
              <option key={lesson.licensee_campaign_id} value={lesson.licensee_campaign_id}>#{lesson.licensee_campaign_id} - {lesson.display_name}</option>
            )
          });
    }
    return null;
  }

  render() {
    const { assignLessonSelected } = this.state;
    const { show, size, assignCreditData } = this.props;
    return (
      <ModalTW show={show} size={size}>
        {assignCreditData.loading === false && assignCreditData.success === null && (
            <div className="flex flex-col justify-between p-6 space-y-4 w-full">
                <div className="flex flex-col mb-6 space-y-2">
                    <h2 className="text-xl font-bold mb-2">Assign Lesson Credit</h2>
                    <p className="font-medium">Choose a Lesson Program from the Active programs in the list.</p>
                <div>
                <div className="flex sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 w-full">
                    <div className="flex mt-1 sm:mt-0 sm:col-span-3 w-full">
                        <div className="w-full rounded-md shadow-sm">
                        <select 
                          id="licenseeCampaign" 
                          className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                          value={this.state.assignLessonSelected || ''} 
                          onChange={this.handleChooseLessonProgram}
                        >
                          <option value="">Choose a Lesson Program</option>
                          {this.assignLessonProgramOptions()}
                        </select>
                        </div>
                    </div>
                </div>
              </div>
                      
              </div>
              <div className="flex flex-col justify-end space-y-2">
                  <Button 
                      bgColor="green"
                      hoverColor="green"
                      onClick={this.handleAssignCreditConfirm}
                      title="Assign 1 Lesson Credit"
                      disabled={assignLessonSelected === null || assignLessonSelected === ''}
                      block
                      size="lg"
                  />    
                  <Button 
                      bgColor="gray"
                      hoverColor="gray"
                      onClick={this.handleAssignCreditClose}
                      title="Cancel"
                      block
                      size="lg"
                  />    
              </div>
          </div>
        )}
          
          {assignCreditData.loading === true && (
              <Loading />
          )}
          {assignCreditData.success !== null && (
              <div className="flex flex-col justify-between p-6 space-y-4 w-full">
                  <div className="flex flex-col mb-6 space-y-2">
                    <h2 className="text-xl font-bold mb-2">Lesson Credit Assigned</h2>
                    <p className="font-medium">A Lesson credit has been assigned successfully!</p>
                  </div>
                  <div className="flex">
                      <Button 
                          bgColor="gray"
                          hoverColor="gray"
                          onClick={() => this.props.onComplete()}
                          title="Close"
                          block
                          size="lg"
                      />    
                  </div>
              </div>
          )}
      </ModalTW>
    );
  }
}


ModalAssignLessonCredit.defaultProps = {
  lessons: null,
  show: false,
  size: 'lg',
  userId: null,
  licenseeId: null,
  onClose(){},
  onComplete(){}
};

const mapStateToProps = ({ auth, licensee, licenseeCampaign, user }) => {
  const { authUser } = auth;
  const { licenseeData } = licensee;
  return {
    licenseeData,
    authUser,
    lessons: licenseeCampaignSelectors.lessons(licenseeCampaign),
    assignCreditData: userSelectors.assignCreditData(user),
  };
};

const mapDispatchToProps = {
  getLicenseeCampaignsByLicenseeId,
  assignCreditToUser,
  assignCreditToUserReset,
  getLicenseeDataByUserId
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignLessonCredit);