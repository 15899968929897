
import * as actionTypes from '../actions/target/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    createTargetSuccess: null,
    createTargetLoading: false,
    createTargetError: false,

    // targetsForUser: null,
    // getTargetsForUserLoading: false,
    // getTargetsForUserError: false,

    targetLibrary: null,
    getTargetLibraryLoading: false,
    getTargetLibraryError: false,
};

function target(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                createTargetSuccess: null,
                createTargetLoading: false,
                createTargetError: false,
            
                targetsForUser: null,
                getTargetsForUserLoading: false,
                getTargetsForUserError: false,
            
                targetLibrary: null,
                getTargetLibraryLoading: false,
                getTargetLibraryError: false
            }

        case actionTypes.CREATE_TARGET:
            return {
                ...state,
                createTargetSuccess: null,
                createTargetLoading: true,
                createTargetError: false,
            }

        case actionTypes.CREATE_TARGET_SUCCESS:
            return {
                ...state,
                createTargetSuccess: action.payload,
                createTargetLoading: false,
                createTargetError: false,
            }

        case actionTypes.CREATE_TARGET_FAIL:
            return {
                ...state,
                createTargetSuccess: null,
                createTargetLoading: false,
                createTargetError: action.payload,
            }

        case actionTypes.RESET_CREATE_TARGET:
            return {
                ...state,
                createTargetSuccess: null,
                createTargetLoading: false,
                createTargetError: null,
            }
        
        // case actionTypes.GET_TARGETS_FOR_USER:
        //     return {
        //         ...state,
        //         targetsForUser: null,
        //         getTargetsForUserError: null,
        //         getTargetsForUserLoading: true,
        //     }

        // case actionTypes.GET_TARGETS_FOR_USER_SUCCESS:
        //     return {
        //         ...state,
        //         targetsForUser: action.payload,
        //         getTargetsForUserError: null,
        //         getTargetsForUserLoading: false,
        //     }

        // case actionTypes.GET_TARGETS_FOR_USER_FAIL:
        //     return {
        //         ...state,
        //         targetsForUser: null,
        //         getTargetsForUserError: action.payload,
        //         getTargetsForUserLoading: false,
        //     }
        
        case actionTypes.GET_TARGET_LIBRARY:
            return {
                ...state,
                targetLibrary: null,
                getTargetsLibraryError: null,
                getTargetsLibraryLoading: true,
            }

        case actionTypes.GET_TARGET_LIBRARY_SUCCESS:
            return {
                ...state,
                targetLibrary: action.payload,
                getTargetsLibraryError: null,
                getTargetsLibraryLoading: false,
            }

        case actionTypes.GET_TARGET_LIBRARY_FAIL:
            return {
                ...state,
                targetLibrary: null,
                getTargetsLibraryError: action.payload,
                getTargetsLibraryLoading: false,
            }
        default:
            return state;
    }
}

export default target;