import * as services from './services';
import * as actionCreators from './actions';

/**
 * assignCreditToUser
 * @param {int} userId the user id we are assigning the credit TO
 * @param {int} licenseeCampaignId the licensee campaign id
 * @returns 
 */
// export const assignCreditToUser = (userId, licenseeCampaignId, apiKey, email=true) => dispatch => {
//     dispatch(actionCreators.assignCreditToUser(userId, licenseeCampaignId));
//     return services.assignCreditToUser(userId, licenseeCampaignId, apiKey, email)
//         .then(data => {
//             dispatch(actionCreators.assignCreditToUserSuccess(data.data));
//         }).catch(e => dispatch(actionCreators.assignCreditToUserFail(e)));
// };

// export const assignCreditToUserReset = () => dispatch => {
//     dispatch(actionCreators.assignCreditToUserReset());
// }

export const inviteCreditForUser = (email, licenseeCampaignId, isPaid) => dispatch => {
    dispatch(actionCreators.inviteCreditForUser(email, licenseeCampaignId, isPaid));
    return services.inviteCreditForUser(email, licenseeCampaignId, isPaid)
        .then(data => dispatch(actionCreators.inviteCreditForUserSuccess(data)))
        .catch(e => dispatch(actionCreators.inviteCreditForUserFail(e)));
}

export const inviteCreditForUserReset = () => dispatch => {
    dispatch(actionCreators.inviteCreditForUserReset());
}