export const APPLY_TO_SWINGBOT = 'user/apply-to-swingbot';
export const APPLY_TO_SWINGBOT_SUCCESS = 'user/apply-to-swingbot-success';
export const APPLY_TO_SWINGBOT_FAIL = 'user/apply-to-swingbot-fail';
export const APPLY_TO_SWINGBOT_RESET = 'user/apply-to-swingbot-reset';

export const GET_USER_DATA_BY_ID = 'user/get-data-by-id';
export const GET_USER_DATA_BY_ID_SUCCESS = 'user/get-data-by-id-success';
export const GET_USER_DATA_BY_ID_FAIL = 'user/get-data-by-id-fail';

export const GET_LICENSEE_USER_DATA_BY_ID = 'user/get-licensee-data-by-id';
export const GET_LICENSEE_USER_DATA_BY_ID_SUCCESS = 'user/get-licensee-data-by-id-success';
export const GET_LICENSEE_USER_DATA_BY_ID_FAIL = 'user/get-licensee-data-by-id-fail';
export const GET_LICENSEE_USER_DATA_BY_ID_RESET = 'user/get-licensee-data-by-id-reset';

export const GET_APPLICATION_WITH_NONCE = 'user/get-application-w-nonce';
export const GET_APPLICATION_WITH_NONCE_SUCCESS = 'user/get-application-w-nonce-success';
export const GET_APPLICATION_WITH_NONCE_FAIL = 'user/get-application-w-nonce-fail';

// export const GET_USERS_BY_LICENSEE_ID = 'user/get-users-by-licensee-id';
// export const GET_USERS_BY_LICENSEE_ID_SUCCESS = 'user/get-users-by-licensee-id-success';
// export const GET_USERS_BY_LICENSEE_ID_FAIL = 'user/get-users-by-licensee-id-fail';

export const ACCEPT_APPLICATION = 'user/accept-application';
export const ACCEPT_APPLICATION_SUCCESS = 'user/accept-application-success';
export const ACCEPT_APPLICATION_FAIL = 'user/accept-application-fail';

export const DENY_APPLICATION = 'user/deny-application';
export const DENY_APPLICATION_SUCCESS = 'user/deny-application-success';
export const DENY_APPLICATION_FAIL = 'user/deny-application-fail';

export const ASSIGN_CREDIT_TO_USER = 'user/assign-credit-to-user';
export const ASSIGN_CREDIT_TO_USER_SUCCESS = 'user/assign-credit-to-user-success';
export const ASSIGN_CREDIT_TO_USER_FAIL = 'user/assign-credit-to-user-fail';
export const ASSIGN_CREDIT_TO_USER_RESET = 'user/assign-credit-to-user-reset';

export const UPDATE_USER_PROFILE = 'user/update-profile';
export const UPDATE_USER_PROFILE_SUCCESS = 'user/update-profile-success';
export const UPDATE_USER_PROFILE_FAIL = 'user/update-profile-fail';
export const UPDATE_USER_PROFILE_RESET = 'user/update-profile-reset';

export const GET_TARGETS_FOR_USER = 'user/get-targets-for-user';
export const GET_TARGETS_FOR_USER_SUCCESS = 'user/get-targets-for-user-success';
export const GET_TARGETS_FOR_USER_FAIL = 'user/get-targets-for-user-fail';