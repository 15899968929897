import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent } from '../tailwind';
import { getTargetLibrary } from '../../actions/target';
import { getTargetsForUser } from '../../actions/user';
import Loading from '../common/Loading';
import ImageWithError from '../common/ImageWithError';
import { selectors as authSelectors } from '../../reducers/auth';
import { selectors as userSelectors } from '../../reducers/user';
import CameraAngleNav from '../common/CameraAngleNav';

class LessonTargetLibraryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.props.location.state.swingTypeId || 1,
            targetLibrary: 1,
            filter: "",
            cameraAngle: this.props.location.state.swingTypeId || 1,
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        this.props.getTargetsForUser(authUser.user_id);
        this.props.getTargetLibrary(); // swingbot
    }

    toggleCameraAngle = () => {
        const newAngle = this.state.cameraAngle === 1 ? 2 : 1;
        this.setState({
            cameraAngle: newAngle,
            selectedTab: newAngle
        });
    }

    toggleTargetLibrary = () => {
        const newLibrary = this.state.targetLibrary === 1 ? 2 : 1;
        this.setState({
            targetLibrary: newLibrary,
        });
    }

    handleChangeCameraAngle = (e) => {
        this.setState({
            selectedTab: parseInt(e.target.value)
        });
    }

    handleClickTarget = (targetData, program) => {
        try {
            const programForTab = this.props.location.state.programsForCampaign
                .filter(program => program.swing_type_id === this.state.cameraAngle);

            let programIdForTab = null;
            if (programForTab.length > 0) {
                programIdForTab = programForTab[0].program_id;
            }

            if (programIdForTab !== null) {    
                this.props.history.push({
                    pathname: `/lessons/targets/${targetData.target_id}`,
                    state: { 
                        targetId: targetData.target_id,
                        targetData,
                        programId: targetData.program_id,
                        sourceProgramId: programIdForTab,
                        readOnly: true,
                        isEditable: this.props.location.state.isEditable // overrides readOnly!
                    }
                });
            }
        } catch(e) {
        }
    }

    renderPrograms = () => {
        const { targetLibrary } = this.props;
        return targetLibrary 
            ? targetLibrary
                .filter(program => program.swing_type_id === this.state.cameraAngle)
                .sort((a,b) => (a.lexicon_name > b.lexicon_name) ? 1 : -1)
                .map(record => this.renderProgramGrid(record))
            : null;
    }

    isInProgram = (targetDisplayName, targets) => {
        let isInProgram = false;
        targets.forEach(target => {
            if (target.display_name === targetDisplayName) {
                isInProgram = true;
            }
        });
        return isInProgram;
    }


    renderProgramGrid = (program) => this.renderTargets(program, program.targets);

    renderMyTargets = () => {
        const { targetsForUser } = this.props;
        const program = this.props.location.state.program;
        // lets sort these alphabetically..
        return targetsForUser !== null 
            ? this.renderTargets(program, targetsForUser) 
            : null;
    }

    renderTargets = (program, targets) => {
        const { selectedTab, targetLibrary } = this.state;
        const { authUser } = this.props;
        // const userImage = authUser.avatar_url;
        const swingbotImage = require('../../img/logo.png');
        let uniqueTargetIds=[];
        targets.forEach(target => {
            if (uniqueTargetIds.indexOf(target.target_id) < 0) {
                uniqueTargetIds.push(target.target_id);
            }
        });
        return targets ? targets
            .filter((target, index) => uniqueTargetIds.indexOf(target.target_id) === index)
            .filter(target => target.swing_type_id === selectedTab)
            .sort((a,b) => (a.lexicon_name > b.lexicon_name) ? 1 : -1)
            .filter(record => {
                return this.state.filter !== ""
                    ? record.display_name.toLowerCase().indexOf(this.state.filter) > -1
                    : true;
            })
            .map((target, index) => {
            return (
                <tr className="py-0" key={`target-${index}`}>
                    <td className="px-0 flex-wrap rounded hover:bg-gray-100 overflow-hidden">
                        <div
                            onClick={() => this.handleClickTarget(target, program)}  
                            className="flex flex-1 bg-white shadow items-center border border-gray-100 rounded mb-2 hover:bg-gray-50 w-full"
                        >
                            <div className="flex flex-1 flex-row w-full">
                                {targetLibrary === 1 && (
                                    <div className="flex flex-0 items-start justify-center p-4 bg-blue-600 rounded rounded-r-none">
                                        <ImageWithError 
                                            className="h-12 w-12 rounded-full" 
                                            src={authUser.avatar} 
                                            alt="" 
                                            errorSrc={require('../../img/user.png')}
                                        />
                                    </div>
                                )}
                                {targetLibrary === 2 && (
                                    <div className="flex flex-0 items-start justify-center p-4 bg-green-600 rounded rounded-r-none">
                                        <ImageWithError 
                                            className="h-12 w-12 rounded-full" 
                                            src={swingbotImage} 
                                            alt="" 
                                            errorSrc={require('../../img/user.png')}
                                        />
                                    </div>
                                )}
                                <div className="flex flex-1 w-full justify-between p-6">
                                    <div className="text-base leading-5 font-medium text-gray-800 cursor-pointer">
                                        <div className="text-gray-800 text-lg font-bold mb-2">{target.display_name}</div>
                                        <div className="flex-wrap text-md text-gray-600 break-normal">{target.text_definition}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }) : null;
    }

    handleChangeFilter = (e) => {
        this.setState({
            filter: e.target.value
        })
    }

    handleCreateTarget = () => {
        // we have to determine the program based on the selected Tab..
        const { planInformation } = this.props;
        
        const programsForCampaign = this.props.location.state.programsForCampaign;
        let programId = null;
        let programSelected = null;
        programsForCampaign.forEach(program => {
            if (program.swing_type_id === this.state.selectedTab) {
                programId = program.program_id;
                programSelected = program;
            }
        });

        // lets do a quick check on the # targets created...
        if (planInformation.targetsAllowed > programSelected.targets.length) {
            this.props.history.push({
                pathname: '/lessons/targets/create',
                state: { 
                    targetInterpretationData: this.props.targetInterpretationData,
                    programId,
                    program: programSelected,
                    cameraAngle: this.props.location.state.swingTypeId
                }
            });
        } else {
            alert('You may only create ' + planInformation.targetsAllowed + ' targets under this plan.');
        }
    }

    render() {
        const { targetLibrary } = this.state;
        const { getTargetsForUserLoading, planInformation } = this.props;
        const programName = this.props.location.state.program.display_name;
        const headingParams = planInformation.planFeatureName === 'Elite'
            ? {
                title: (this.props.location.state.swingTypeId === 1 ? 'Face On Lesson Target Library':'Down the Line Lesson Target Library'),
                backTitle: "Back to Lessons",
                buttonTitle: (this.props.location.state.swingTypeId === 1 
                    ? "Create Custom Face On Lesson Target"
                    : "Create Custom Down the Line Target"),
                buttonClassName: "bg-green-300",
                onClick: this.handleCreateTarget
            } : {
                title: (this.props.location.state.swingTypeId === 1 ? 'Face On Lesson Target Library':'Down the Line Lesson Target Library')
            };
        return (
            <DashboardContent 
                title={null} 
                subtitle={(this.props.location.state.swingTypeId === 1 ? 'A Collection of Face On Lesson Targets':'A Collection of Down the Line Lesson Targets')}
                backTitle={`Back to ${programName}`}
                {...headingParams}
            >
                <div>
                {targetLibrary === 1 && (
                    <div>
                        {getTargetsForUserLoading === false && (
                            <div className="">
                                <CameraAngleNav
                                    filter={this.state.filter}
                                    onChangeFilter={this.handleChangeFilter}
                                    onChangeCameraAngle={this.toggleCameraAngle}
                                    onChangeTargetLibrary={this.toggleTargetLibrary}
                                    cameraAngle={this.state.cameraAngle}
                                    targetLibrary={this.state.targetLibrary}
                                    showSwingFilters={false}
                                />
                                <div className="cursor-pointer w-full">
                                    <div className="col-span-1 w-full">
                                        <table className="min-w-full">
                                            <tbody>
                                                {this.renderMyTargets()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        {getTargetsForUserLoading === true && (
                            <div className="">
                                <Loading />
                            </div>
                        )}
                    </div>
                )}

                {targetLibrary === 2 && (
                    <div>
                        <CameraAngleNav
                            filter={this.state.filter}
                            onChangeFilter={this.handleChangeFilter}
                            onChangeCameraAngle={this.toggleCameraAngle}
                            onChangeTargetLibrary={this.toggleTargetLibrary}
                            cameraAngle={this.state.cameraAngle}
                            targetLibrary={this.state.targetLibrary}
                            showSwingFilters={false}
                        />
                        <div className="">
                            <div className="cursor-pointer w-full">
                                <div className="col-span-1 w-full">
                                    <table className="min-w-full">
                                        <tbody>
                                            {this.renderPrograms()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </DashboardContent>
        );
    }
}

LessonTargetLibraryPage.defaultProps = {

}

const mapStateToProps = ({ auth, target, user }) => {
    const { 
        targetLibrary,
        getTargetLibraryError,
        getTargetLibraryLoading,
        targetsForUser,
        getTargetsForUserError,
        getTargetsForUserLoading,
    } = target;
    const { authUser } = auth;
    return {
        planInformation: authSelectors.planInformation(auth), 
        authUser,
        targetLibrary,
        getTargetLibraryError,
        getTargetLibraryLoading,
        targetsForUser: userSelectors.targets(user).data,
        getTargetsForUserError: userSelectors.targets(user).error,
        getTargetsForUserLoading: userSelectors.targets(user).loading,
    };
};

const mapDispatchToProps = {
    getTargetsForUser,
    getTargetLibrary
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonTargetLibraryPage);