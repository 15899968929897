import React from 'react';
import { connect } from 'react-redux';
import ModalTW from '../../common/modal/ModalTW';
import { Button, Loading } from '../../common';
import { getLicenseeDataByUserId } from '../../../actions/licensee';
import { getLicenseeCampaignsByLicenseeId } from '../../../actions/licenseeCampaign';
import { inviteCreditForUser, inviteCreditForUserReset } from '../../../actions/credit';
import { selectors as licenseeCampaignSelectors } from '../../../reducers/licenseeCampaign';
import { selectors as creditSelectors } from '../../../reducers/credit';

class ModalInviteLessonCredit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      assignLessonSelected: props.licenseeCampaignId,
      email: ''
    }
  }

  componentDidMount = () => {
    const { lessons, authUser, licenseeData } = this.props;
    this.props.inviteCreditForUserReset();
    // if no lessons, lets get them!
    if (lessons === null) {
      this.props.getLicenseeCampaignsByLicenseeId(authUser.licensee_id);
    }
    if (licenseeData === null) {
      this.props.getLicenseeDataByUserId(authUser.user_id);
    }
  }

  handleAssignCreditConfirm = () => {
    const { assignLessonSelected, email } = this.state;
    if ((assignLessonSelected !== null && assignLessonSelected !== '')) {
      // let's assign the credit to the user
      const isPaid = true;
      this.props.inviteCreditForUser(email, assignLessonSelected, isPaid);
    }
  }

  handleFieldChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleChooseLessonProgram = (e) => {
      this.setState({
        assignLessonSelected: e.target.value,
      })
  }

  handleAssignCreditClose = () => {
      this.props.onClose();
  }

  handleAssignCreditComplete = () => {
    this.props.onComplete();
  }

  assignLessonProgramOptions = () => {
    const { lessons } = this.props;
    if (lessons !== null) { 
        return lessons
          .filter(lesson => lesson.licensee_campaign_status === 'ACTIVE')
          .map(lesson => (
              <option key={lesson.licensee_campaign_id} value={lesson.licensee_campaign_id}>{lesson.display_name}</option>
          ));
    }
    return null;
  }

  render() {
    const { assignLessonSelected } = this.state;
    const { show, size, inviteCredit, licenseeCampaignId } = this.props;
    return (
      <ModalTW show={show} size={size}>
        {inviteCredit.loading === false && inviteCredit.success === null && (
            <div className="flex flex-col justify-between p-6 space-y-4 w-full">
                <div className="flex flex-col mb-6 space-y-2 text-gray-700">
                    <h2 className="text-2xl font-bold mb-2">Invite User to Lesson Program</h2>
                    <p className="font-medium">Send an invitation to the email address specified that will grant the user 1 Lesson Credit to this Lesson Program.</p>
                    <p className="font-medium">If the user is not yet registered, the invitation will ask them to create an account.</p>
                <div>
                <div className="flex w-full">

                    <div className="flex mt-1 sm:mt-0 w-full">
                      <div className="flex flex-col mt-6 w-full">
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Email Address
                        </label>
                        <div className="flex mt-1 w-full">
                            <div className="w-full rounded-md shadow-sm">
                            <input 
                              id="email" 
                              value={this.state.email} 
                              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                              onChange={this.handleFieldChange} />
                            </div>
                        </div>
                      </div>
                    </div>
                    {licenseeCampaignId === null && (
                      <div className="flex mt-1 sm:mt-0 sm:col-span-3 w-full">
                          <div className="w-full rounded-md shadow-sm">
                            <select 
                              id="licenseeCampaign" 
                              className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                              value={this.state.assignLessonSelected || ''} 
                              onChange={this.handleChooseLessonProgram}
                            >
                              <option value="">Choose a Lesson Program</option>
                              {this.assignLessonProgramOptions()}
                            </select>
                          </div>
                      </div>
                    )}
                </div>
              </div>
                      
              </div>
              <div className="flex flex-col justify-end space-y-2">
                  <Button 
                      bgColor="green"
                      hoverColor="green"
                      onClick={this.handleAssignCreditConfirm}
                      title="Assign 1 Lesson Credit"
                      disabled={(assignLessonSelected === null || assignLessonSelected === '') || this.state.email === ''}
                      block
                      size="lg"
                  />    
                  <Button 
                      bgColor="gray"
                      hoverColor="gray"
                      onClick={this.handleAssignCreditClose}
                      title="Cancel"
                      block
                      size="lg"
                  />    
              </div>
          </div>
        )}
          
          {inviteCredit.loading === true && (
              <Loading />
          )}
          {inviteCredit.success !== null && (
              <div className="flex flex-col justify-between p-6 space-y-4 w-full">
                  <div className="flex flex-col mb-6 space-y-2">
                    <h2 className="text-xl font-bold mb-2">Invitation Created</h2>
                    <p className="font-medium">An invitation has been sent via email.</p>
                  </div>
                  <div className="flex">
                      <Button 
                          bgColor="gray"
                          hoverColor="gray"
                          onClick={this.handleAssignCreditClose}
                          title="Close"
                          block
                          size="lg"
                      />    
                  </div>
              </div>
          )}
      </ModalTW>
    );
  }
}


ModalInviteLessonCredit.defaultProps = {
  lessons: null,
  show: false,
  size: 'lg',
  userId: null,
  licenseeId: null,
  licenseeCampaignId: null,
  onClose(){},
  onComplete(){}
};

const mapStateToProps = ({ auth, licensee, licenseeCampaign, credit }) => {
  const { authUser } = auth;
  const { licenseeData } = licensee;
  return {
    licenseeData,
    authUser,
    lessons: licenseeCampaignSelectors.lessons(licenseeCampaign),
    inviteCredit: creditSelectors.inviteCredit(credit),
  };
};

const mapDispatchToProps = {
  getLicenseeCampaignsByLicenseeId,
  inviteCreditForUser,
  inviteCreditForUserReset,
  getLicenseeDataByUserId
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInviteLessonCredit);