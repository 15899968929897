import { swingbotApi } from '../../util/api';

/**
 * analyzeVideo
 * @param {int} videoId the video to analyze
 * @returns 
 * UPDATED
 */
export const analyzeVideo = (videoId) => {
    return swingbotApi(`analysis/${videoId}`, { videoId }, 'get', true, 'analysis')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const convertCalculationToEnglish = (pseudo) => {
    return swingbotApi(`calculations/english`, { calculationPseudo: pseudo }, 'post', true, 'analysis')
        .then(response => response)
        .catch(err => Promise.reject(err));   
}

/**
 * convertEquationToEnglish
 * - translate the pseudo code to english
 * @param {*} pseudo1 the pseudo calculation
 * @param {*} operand "LESS_THAN" etc
 * @param {*} pseudo2 the pseudo calculation
 * UPDATED 
 * @returns 
 */
export const convertEquationToEnglish = (pseudo1, operand, pseudo2) => {
    return swingbotApi(
        `equation/english`, { 
            calculationPseudo1: pseudo1, 
            operand: operand, 
            calculationPseudo2: pseudo2 
        }, 
        'post', 
        true, 
        'equation')
        .then(response => response)
        .catch(err => Promise.reject(err));   
}