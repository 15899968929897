import { swingbotApi } from '../../util/api';

export const getTargetInterpretations = () => {
    return swingbotApi('interpretation', {}, 'get', true, 'interpretation')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetInterpretationsForProgram = (programId) => {
    return swingbotApi(`program/${programId}/target-interpretations`, { }, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * getTargetInterpretationsForTarget
 * @param {int} targetId the target id
 * @version 2.0
 * @returns 
 */
export const getTargetInterpretationsForTarget = (targetId) => {
    return swingbotApi(`target/${targetId}/interpretations`, { }, 'get', true, 'target')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createTargetInterpretationData = (data) => {
    return swingbotApi(`interpretation`, { ...data }, 'put', true, 'interpretation')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const removeTargetInterpretationFromTarget = (userId, targetInterpretationId) => {
    return swingbotApi(`interpretation`, { userId, targetInterpretationId }, 'delete', true, 'interpretation')
        .then(response => response)
        .catch(err => Promise.reject(err));
};