import { swingbotApi } from '../../util/api';

export const applyToSwingbot = (
    email, 
    firstName,
    lastName,
    websiteAddress, 
    paymentCollectionMethod, 
    isGolfInstructor, 
    instagramHandle, 
    twitterHandle, 
    facebookPage
    ) => {
    return swingbotApi(`application`, { 
        email, 
        firstName,
        lastName,
        websiteAddress, 
        paymentCollectionMethod, 
        isGolfInstructor, 
        instagramHandle, 
        twitterHandle, 
        facebookPage
     }, 'post', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const acceptApplication = (nonce) => {
    return swingbotApi(`application/accept/${nonce}`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const denyApplication = (nonce) => {
    return swingbotApi(`application/deny/${nonce}`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getApplicationWithNonce = (nonce) => {
    return swingbotApi(`application/${nonce}`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getUserDataById = (userId, licenseeId, sandbox) => {
    const debug = sandbox ? '?debug=true' : '';
    return swingbotApi(`user/${userId}${debug}`, {}, 'get', true, 'user')
        .then(response => {
            // let's make sure that the user is part of the licensee
            return response;
        })
        .catch(err => Promise.reject(err));
};

// export const getUsersByLicenseeId = (licenseeId, limit, offset) => {
//     return swingbotApi(`licensee/${licenseeId}/users?limit=${limit}&offset=${offset}`, {}, 'get', true, 'licensee')
//         .then(response => response)
//         .catch(err => Promise.reject(err));
// };

export const assignCreditToUser = (userId, licenseeCampaignId, apiKey, email) => {
    return swingbotApi(`user/${userId}/credit`, {
        userId,
        licenseeCampaignId,
        sendEmail: email
    }, 'post', true, 'user')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

// export const inviteCreditForUser = (email, licenseeCampaignId, isPaid) => {
//     return swingbotApi(`credit/invite`, {
//         email,
//         licenseeCampaignId,
//         isPaid
//     }, 'post', true, 'credit')
//         .then(response => response)
//         .catch(err => Promise.reject(err));
// };

export const updateUserProfile = (userId, firstName, lastName) => {
    return swingbotApi(`user/${userId}/profile`, {
        firstName,
        lastName
    }, 'put', true, 'user')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * getTargetsForUser
 * - Fetch all of the targets for the licensee
 * @param {int} userId user id for the licensee
 * @returns 
 */
export const getTargetsForUser = (userId) => {
    return swingbotApi(`user/${userId}/target`, {}, 'get', true, 'user')
        .then(response => response)
        .catch(err => Promise.reject(err));
};


