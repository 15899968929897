import { swingbotApi } from '../../util/api';

// Stripe integration
// const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

/**
 * getWebsiteForLicensee
 * - get the website information for the licensee
 * @param {int} licenseeId the id of the licensee
 * @version 2.0
 * @returns 
 */
export const getWebsiteForLicensee = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/website`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * createWebsiteForLicensee
 * - Create a new subdomain for the instructor
 * @param {string} subdomain the subdomain for the licensee that they desire
 * @param {int} licenseeId the id of the licensee   
 * @param {string} displayName the display name for the website
 * @version 2.0
 * UNTESTED
 * @returns 
 */
export const createWebsiteForLicensee = (subdomain, licenseeId, displayName) => {
    return swingbotApi(`licensee/${licenseeId}/website`, { subdomain, displayName }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * updateWebsiteForLicensee
 * - update the website details that will be used to generate the template website for the licensee
 * 
 * @param {*} title 
 * @param {*} theme_color 
 * @param {*} logo_url 
 * @param {*} job_title 
 * @param {*} display_name 
 * @param {*} twitter_url 
 * @param {*} linkedin_url 
 * @param {*} profile_image_url 
 * @param {*} bio 
 * @param {*} licenseeId 
 * @version 2.0
 * @returns 
 */
export const updateWebsiteForLicensee = (title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/website`, { title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio }, 'put', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * getStripeConnectedAccount
 * - get the Stripe account information based on the Stripe account
 * @param {int} licenseeId the licensee id
 * @param {string} account the Stripe Account Id
 * @param {boolean} debug flag
 * @version 2.0
 * @returns 
 */
export const getStripeConnectedAccount = (licenseeId, account, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/connect/${account}${debugString}`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createStripeConnectedAccount = (licenseeId, email, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/connect${debugString}`, { email }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const linkStripeConnectedAccount = (licenseeId, accountId, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/link${debugString}`, { account_id: accountId }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};


