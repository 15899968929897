import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import storage from 'redux-persist/lib/storage';
import * as actionTypes from '../actions/auth/actionTypes';

import api from './api';
import auth from './auth';
import licensee from './licensee';
import user from './user';
import analyze from './analyze';
import program from './program';
import licenseeCampaign from './licenseeCampaign';
import targetInterpretation from './targetInterpretation';
import settings from './settings';
import plan from './plan';
import target from './target';
import line from './line';
import subscribe from './subscribe';
import website from './website';
import slack from './slack';
import credit from './credit';

const appReducer = combineReducers({
    api,
    auth,
    licensee,
    user,
    analyze,
    program,
    licenseeCampaign,
    targetInterpretation,
    settings,
    plan,
    target,
    line,
    subscribe,
    website,
    slack,
    routing: routerReducer,
    credit,
});

const swingbotSaaSApp = (state, action) => {
    if (action.type === actionTypes.LOGOUT_USER) {
        storage.removeItem('persist:root');
    }
    return appReducer(state, action)
}


export default swingbotSaaSApp;

/*
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { api } from './api';
import * as actionTypes from '../actions/api/types';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
    api,
    routing: routerReducer
});


const swingbotSaaSProApp = (state, action) => {
    if (action.type === actionTypes.LOGOUT) {
        storage.removeItem('persist:root');
    }
    return appReducer(state, action)
}

export default swingbotSaaSProApp;
*/