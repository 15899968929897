import { swingbotApi } from '../../util/api';

/**
 * updateWebhookForLicenseeCampaign
 * - update the endpoint for the campaign webhook
 * @param {int} licenseeCampaignId the licensee campaign id
 * @param {string} endpoint the endpoint that will be receiving the POST results upon completion of processing a video
 * @version 2.0
 * @returns 
 */
export const updateWebhookForLicenseeCampaign = (licenseeCampaignId, endpoint) => {
    return swingbotApi(`campaign/${licenseeCampaignId}/webhooks`, { endpoint }, 'put', true, 'campaign')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const updateWebhooksForLicensee = (licenseeId, endpoint) => {
    return swingbotApi(`licensee/${licenseeId}/webhooks`, { endpoint }, 'put', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};