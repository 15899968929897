import { swingbotApi } from '../../util/api';

/**
 * getPlans
 * - Fetch all of the available plans to purchase
 * @version 2.0
 * @returns 
 */
export const getPlans = () => {
    return swingbotApi(`plan`, { }, 'get', true, 'plan')
        .then(response => response)
        .catch(err => Promise.reject(err));
};