/**
 * Credit
 */
 import * as actionTypes from '../actions/credit/actionTypes';
 
   // set the initial state of the reducer
     const INITIAL_STATE = {
 
         inviteCreditForUserComplete: null,
         inviteCreditForUserLoading: false,
         inviteCreditForUserError: null,
     };
   
    export default (state = INITIAL_STATE, action) => {
      switch (action.type) {
 
         case actionTypes.INVITE_CREDIT_FOR_USER:
             return {
                 ...state,
                 inviteCreditForUserComplete: null,
                 inviteCreditForUserLoading: true,
                 inviteCreditForUserError: null,
             }
         case actionTypes.INVITE_CREDIT_FOR_USER_SUCCESS:
             return {
                 ...state,
                 inviteCreditForUserComplete: action.payload,
                 inviteCreditForUserLoading: false,
                 inviteCreditForUserError: null,
             }
         case actionTypes.INVITE_CREDIT_FOR_USER_FAIL:
             return {
                 ...state,
                 inviteCreditForUserComplete: null,
                 inviteCreditForUserLoading: false,
                 inviteCreditForUserError: action.payload,
             }
         case actionTypes.INVITE_CREDIT_FOR_USER_RESET:
             return {
                 ...state,
                 inviteCreditForUserComplete: null,
                 inviteCreditForUserLoading: false,
                 inviteCreditForUserError: null,
             }
 
        default:
          return state;
      }
    };
   
    export const selectors = {
     inviteCredit: (state) => {
         return {
             success: state.inviteCreditForUserComplete,
             loading: state.inviteCreditForUserLoading,
             error: state.inviteCreditForUserError
         }
     },
   }