import { swingbotApi } from '../../util/api';

/**
 * Braintree Client
 */
/**
 * Get a client token to authorize braintree payments drop-in UI
 * 
 * @param {int} userId 
 * @param {bool} sandbox 
 */

export const getBraintreeClientToken = (userId, sandbox) => {
    return swingbotApi(
        `transaction/braintree-client-token`, { userId, sandbox }, 'post', true, 'transaction')
        .then(response => response)
        .catch(err => Promise.reject(err));
};
/**
 * createBraintreeCustomer
 * 
 * This will create the Braintree Customer in the vault
 * It will return a customer.id from the response from braintree
 * that we can then use to generate the paymentNonce
 */
export const createBraintreeCustomer = (userId, firstName, lastName, email, sandbox) => {
    return swingbotApi(
        `transaction/braintree-customer`, { 
            userId, firstName, lastName, email, sandbox
        }, 
        'post', 
        true, 
        'transaction'
    )
        .then(response => response)
        .catch(err => Promise.reject(err));
};
/**
 * subscribeToPlan
 * @param {int} userId the user id
 * @param {string} paymentNonce the payment nonce we get from the DropIn UI
 * @param {string} planId the plan id (from braintree) that is selected
 * @param {bool} sandbox boolean for test|live account
 */
export const subscribeToPlan = (userId, paymentNonce, planId, sandbox) => {
    return swingbotApi(
        `transaction/braintree-subscribe`, { 
            userId, 
            paymentNonce, 
            planId, 
            sandbox, 
            role: 'licensee'
        }, 
        'post', 
        true, 
        'transaction'
    )
        .then(response => {
            console.log('subscribe to plan ', response);
            return response;
        })
        .catch(err => Promise.reject(err));
};

export const updateSubscriptionPlan = (userId, paymentNonce, planId, subscriptionId, price, sandbox) => {
    console.log('update plan: ', userId, paymentNonce, planId, subscriptionId, price, sandbox);
    return swingbotApi(
        `transaction/braintree-subscribe`, { 
            userId, 
            paymentNonce, 
            planId, 
            subscriptionId, 
            price, 
            sandbox, 
            role: 'licensee'
        }, 
        'put', 
        true, 
        'transaction'
    )
        .then(response => {
            return response;
        })
        .catch(err => Promise.reject(err));
};

export const getSubscriptionsByUserId = (userId, sandbox) => {
    const debug = sandbox === true ? '1' : '0'
    return swingbotApi(
        `users/${userId}/transaction?debug=${debug}`, {}, 
        'get', 
        true, 
        'user'
    )
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const cancelSubscription = (userId, subscriptionId, sandbox) => {
    return swingbotApi(
        `transaction/braintree-cancel`, { 
            userId, subscriptionId, sandbox
        }, 
        'post', 
        true, 
        'transaction')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

