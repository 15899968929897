import * as services from './services';
import * as actionCreators from './actions';

export const statisticsByLicenseeId = (licenseeId, startDate) => dispatch => {
    dispatch(actionCreators.statisticsByLicenseeId(licenseeId, startDate));
    return services.statisticsByLicenseeId(licenseeId, startDate)
        .then(data => dispatch(actionCreators.statisticsByLicenseeIdSuccess(data.data)))
        .catch(e => dispatch(actionCreators.statisticsByLicenseeIdFail(e)));
}

export const getLicenseeDataByUserId = (userId) => dispatch => {
    dispatch(actionCreators.getLicenseeDataByUserId(userId));
    return services.getLicenseeDataByUserId(userId)
        .then(data => dispatch(actionCreators.getLicenseeDataByUserIdSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getLicenseeDataByUserIdFail(e)));
}

export const getLicenseeDataByEmail = (email) => dispatch => {
    dispatch(actionCreators.getLicenseeDataByEmail(email));
    return services.getLicenseeDataByEmail(email)
        .then(data => dispatch(actionCreators.getLicenseeDataByEmailSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getLicenseeDataByEmailFail(e)));
}

/**
 * getLicenseeAnalyses
 * Get all of the videos/analyses that have been/are being processed
 * @param {int} licenseeId the id of the licensee fetching analyses for
 * @param {int} limit 
 * @param {int} offset 
 * @returns 
 */
export const getLicenseeAnalyses = (licenseeId, limit = 100, offset = 0) => dispatch => {
    dispatch(actionCreators.getLicenseeAnalyses(licenseeId));
    return services.getLicenseeAnalyses(licenseeId, limit, offset)
        .then(data => dispatch(actionCreators.getLicenseeAnalysesSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getLicenseeAnalysesFail(e)));
}

export const getLicenseeAnalysesForUser = (licenseeId, userId, limit = 100, offset = 0) => dispatch => {
    dispatch(actionCreators.getLicenseeAnalysesForUser(licenseeId, userId));
    return services.getLicenseeAnalysesForUser(licenseeId, userId, limit, offset)
        .then(data => dispatch(actionCreators.getLicenseeAnalysesForUserSuccess(data.data.data)))
        .catch(e => dispatch(actionCreators.getLicenseeAnalysesForUserFail(e)));
}

export const getLessonCountsByDay = (licenseeId) => dispatch => {
    dispatch(actionCreators.getLessonCountsByDay(licenseeId));
    return services.getLessonCountsByDay(licenseeId)
        .then(data => dispatch(actionCreators.getLessonCountsByDaySuccess(data.data)))
        .catch(e => dispatch(actionCreators.getLessonCountsByDayFail(e)));
}

export const getUsersByLicenseeId = (licenseeId, limit=50, offset=0) => dispatch => {
    dispatch(actionCreators.getUsersByLicenseeId(licenseeId, limit, offset));
    return services.getUsersByLicenseeId(licenseeId, limit, offset)
        .then(data => dispatch(actionCreators.getUsersByLicenseeIdSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getUsersByLicenseeIdFail(e)));
}
