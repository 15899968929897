import * as services from './services';
import * as actionCreators from './actions';
import * as authActionCreators from '../auth/actions';
/**
 * applyToSwingbot
 * The user can apply to be a part of SwingbotPro
 * Not everyone is qualified automatically
 * 
 * @param {object} data 
 */
export const applyToSwingbot = (
    email, 
    firstName,
    lastName,
    websiteAddress, 
    paymentCollectionMethod, 
    isGolfInstructor, 
    instagramHandle, 
    twitterHandle, 
    facebookPage
    ) => dispatch => {
    dispatch(actionCreators.applyToSwingbot(
        email, 
        firstName,
        lastName,
        websiteAddress, 
        paymentCollectionMethod, 
        isGolfInstructor, 
        instagramHandle, 
        twitterHandle, 
        facebookPage
    ));
    return services.applyToSwingbot(
        email, 
        firstName,
        lastName,
        websiteAddress, 
        paymentCollectionMethod, 
        isGolfInstructor, 
        instagramHandle, 
        twitterHandle, 
        facebookPage
    )
        .then(data => dispatch(actionCreators.applyToSwingbotSuccess(data.data)))
        .catch(e => dispatch(actionCreators.applyToSwingbotFail(e)));
}

export const applyToSwingbotReset = () => dispatch => {
    dispatch(actionCreators.applyToSwingbotReset());
}

export const acceptApplication = (nonce) => dispatch => {
    dispatch(actionCreators.acceptApplication(nonce));
    return services.acceptApplication(nonce)
        .then(data => dispatch(actionCreators.acceptApplicationSuccess(data.data)))
        .catch(e => dispatch(actionCreators.acceptApplicationFail(e)));
}

export const denyApplication = (nonce) => dispatch => {
    dispatch(actionCreators.denyApplication(nonce));
    return services.denyApplication(nonce)
        .then(data => dispatch(actionCreators.denyApplicationSuccess(data.data)))
        .catch(e => dispatch(actionCreators.denyApplicationFail(e)));
}

export const getApplicationWithNonce = (nonce) => dispatch => {
    dispatch(actionCreators.getApplicationWithNonce(nonce));
    return services.getApplicationWithNonce(nonce)
        .then(data => dispatch(actionCreators.getApplicationWithNonceSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getApplicationWithNonceFail(e)));
}

/**
 * getUsersByLicenseeId
 * 
 * Get all of the users that "belong" to the licensee id
 * I "believe" we fetch these based on the videos that have been uploaded.
 * 
 * @param {int} licenseeId the licensee id
 * @param {int} limit the limit of number of users to fetch
 * @param {int} offset the offset of the users to fetch
 * @returns 
 */
// export const getUsersByLicenseeId = (licenseeId, limit=50, offset=0) => dispatch => {
//     dispatch(actionCreators.getUsersByLicenseeId(licenseeId, limit, offset));
//     return services.getUsersByLicenseeId(licenseeId, limit, offset)
//         .then(data => dispatch(actionCreators.getUsersByLicenseeIdSuccess(data.data)))
//         .catch(e => dispatch(actionCreators.getUsersByLicenseeIdFail(e)));
// }

/**
 * getUserAnalysisByLicenseeId
 * @param {int} userId the user id we are fetching analyses for
 * @param {int} licenseeId the licensee id that is also used as a filter
 * @returns 
 */
export const getLicenseeUserDataById = (userId, licenseeId, sandbox) => dispatch => {
    dispatch(actionCreators.getLicenseeUserDataById(userId, licenseeId));
    return services.getUserDataById(userId, licenseeId, sandbox)
        .then(data => {
            dispatch(actionCreators.getLicenseeUserDataByIdSuccess(data.data));
        }).catch(e => dispatch(actionCreators.getUserDataByIdFail(e)));
};

export const getLicenseeUserDataByIdReset = () => dispatch => {
    dispatch(actionCreators.getLicenseeUserDataByIdReset());
}

export const getUserDataById = (userId, licenseeId, sandbox) => dispatch => {
    dispatch(actionCreators.getUserDataById(userId, licenseeId));
    return services.getUserDataById(userId, licenseeId, sandbox)
        .then(data => {
            dispatch(authActionCreators.updateSubscriptionPlan(data.data));
        }).catch(e => dispatch(actionCreators.getUserDataByIdFail(e)));
}

/**
 * assignCreditToUser
 * @param {int} userId the user id we are assigning the credit TO
 * @param {int} licenseeCampaignId the licensee campaign id
 * @returns 
 */
export const assignCreditToUser = (userId, licenseeCampaignId, apiKey, email=true) => dispatch => {
    dispatch(actionCreators.assignCreditToUser(userId, licenseeCampaignId));
    return services.assignCreditToUser(userId, licenseeCampaignId, apiKey, email)
        .then(data => {
            dispatch(actionCreators.assignCreditToUserSuccess(data.data));
        }).catch(e => dispatch(actionCreators.assignCreditToUserFail(e)));
};

export const assignCreditToUserReset = () => dispatch => {
    dispatch(actionCreators.assignCreditToUserReset());
}

// export const inviteCreditForUser = (email, licenseeCampaignId, isPaid) => dispatch => {
//     dispatch(actionCreators.inviteCreditForUser(email, licenseeCampaignId, isPaid));
//     return services.inviteCreditForUser(email, licenseeCampaignId, isPaid)
//         .then(data => dispatch(actionCreators.inviteCreditForUserSuccess(data)))
//         .catch(e => dispatch(actionCreators.inviteCreditForUserFail(e)));
// }

// export const inviteCreditForUserReset = () => dispatch => {
//     dispatch(actionCreators.inviteCreditForUserReset());
// }

export const updateUserProfile = (userId, firstName, lastName, history=null) => dispatch => {
    dispatch(actionCreators.updateUserProfile(userId, firstName, lastName));
    return services.updateUserProfile(userId, firstName, lastName)
        .then(data => {
            dispatch(actionCreators.updateUserProfileSuccess(data.data));
            if (history) {
                history.go(0);
            }
        }).catch(e => dispatch(actionCreators.updateUserProfileFail(e)));
};


export const getTargetsForUser = (userId) => dispatch => {
    dispatch(actionCreators.getTargetsForUser(userId));
    return services.getTargetsForUser(userId)
        .then(data => dispatch(actionCreators.getTargetsForUserSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetsForUserFail(e)));
}