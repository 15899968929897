import { swingbotApi } from '../../util/api';

/**
 * createTarget
 * - create a new target for a program (face on, down the line, etc) within a campaign
 * @param {int} programId the program id 
 * @param {int} userId the user id creating the target
 * @param {string} displayName the display name for the target
 * @param {int} swingTypeId the swing type id (1|2)
 * @param {string} textDefinition the definition for the target
 * @version 2.0
 * @returns 
 */
export const createTarget = (programId, userId, displayName, swingTypeId, textDefinition) => {
    return swingbotApi(
            'target', 
            { programId, userId, displayName, swingTypeId, textDefinition }, 
            'post', 
            true, 
            'target'
        )
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetsForUser = (userId) => {
    return swingbotApi(`user/${userId}/target`, {}, 'get', true, 'target')
        .then(response => {
            return response;
        })
        .catch(err => Promise.reject(err));
};

export const addTargetFromLibrary = (targetId, programId, userId) => {
    return swingbotApi(`library/target`, { targetId, programId, userId }, 'post', true, 'target')
        .then(response => response)
        .catch(err => Promise.reject(err));
};



