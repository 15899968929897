import { swingbotApi } from '../../util/api';
import axios from 'axios';
import queryString from 'query-string';

/**
 * verifySlackCode
 * This method will get called when the user has allowed Slack to talk to SwingbotPro Slack App, and Slack redirects us to this page.
 * 
 * We have to send the code to Slack in order to get a proper token, which we will save into the database for the user so we can send them messages.
 * 
 * @param {string} code 
 * @returns 
 */
// sample response
//  {
//     "ok": true,
//     "app_id": "A026AHADJG3",
//     "authed_user": {
//         "id": "U01DU7QRSJK"
//     },
//     "scope": "incoming-webhook",
//     "token_type": "bot",
//     "access_token": "xoxb-1508845747552-2214667841667-YVeCKdZwllrMuDyGkRfoE8bs",
//     "bot_user_id": "U026AKMQRKM",
//     "team": {
//         "id": "T01EYQVMZG8",
//         "name": "SwingbotPro"
//     },
//     "enterprise": null,
//     "is_enterprise_install": false,
//     "incoming_webhook": {
//         "channel": "#general",
//         "channel_id": "C01EMJM4Y2V",
//         "configuration_url": "https://swingbotproworkspace.slack.com/services/B026P3K8G7K",
//         "url": "https://hooks.slack.com/services/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"
//     }
// }
export const verifySlackCode = (code) => {
    const slackEndpoint = 'https://slack.com/api/oauth.v2.access';
    const clientId = '1508845747552.2214588460547';
    const clientSecret = '673e63ad1f93c94af09a8ede8cc9e7c4';
    const data = {
        client_id: clientId,
        client_secret: clientSecret,
        code,
        // redirect_uri: 'https://www.swingbotpro.com/integrations/slack/oauth'
    };

    const string = queryString.stringify(data);

    return axios(slackEndpoint, {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: string
      })
        .then(data => {
            console.log('SLACK RESPONSE ', data);
            return data
        }).catch(e => e.message);
};


export const saveSlackWebhookForLicensee = (licenseeId, channel, webhook) => {
    return swingbotApi(`licensee/${licenseeId}/slack/webhook`, { channel, webhook }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

/**
 * getSlackWebhookForLicensee
 * @param {int} licenseeId the licensee id
 * @returns 
 * @version 2.0
 */
export const getSlackWebhookForLicensee = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/slack/webhook`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};