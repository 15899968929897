import * as actionTypes from './actionTypes';

/**
 * applyToSwingbot
 * @param {*} userId 
 */
export const applyToSwingbot = (
    email, 
    firstName,
    lastName,
    websiteAddress, 
    paymentCollectionMethod, 
    isGolfInstructor, 
    instagramHandle, 
    twitterHandle, 
    facebookPage
    ) => ({
        type: actionTypes.APPLY_TO_SWINGBOT,
        payload: {
            email, 
            firstName,
            lastName,
            websiteAddress, 
            paymentCollectionMethod, 
            isGolfInstructor, 
            instagramHandle, 
            twitterHandle, 
            facebookPage
        }
});

export const applyToSwingbotSuccess = (data) => ({
    type: actionTypes.APPLY_TO_SWINGBOT_SUCCESS,
    payload: data
});

export const applyToSwingbotFail = (error) => ({
    type: actionTypes.APPLY_TO_SWINGBOT_FAIL,
    payload: error
});

export const applyToSwingbotReset = () => ({
    type: actionTypes.APPLY_TO_SWINGBOT_RESET,
    payload: null
});

/**
 * acceptApplication
 * @param {*} nonce 
 */
export const acceptApplication = (nonce) => ({
    type: actionTypes.ACCEPT_APPLICATION_SUCCESS,
    payload: nonce
});

export const acceptApplicationSuccess = (data) => ({
    type: actionTypes.ACCEPT_APPLICATION_SUCCESS,
    payload: data
});

export const acceptApplicationFail = (error) => ({
    type: actionTypes.ACCEPT_APPLICATION_FAIL,
    payload: error
});

/**
 * deny application
 */
export const denyApplication = (nonce) => ({
    type: actionTypes.DENY_APPLICATION_SUCCESS,
    payload: nonce
});

export const denyApplicationSuccess = (data) => ({
    type: actionTypes.DENY_APPLICATION_SUCCESS,
    payload: data
});

export const denyApplicationFail = (error) => ({
    type: actionTypes.DENY_APPLICATION_FAIL,
    payload: error
});

/**
 * getApplicationWithNonce
 */
export const getApplicationWithNonce = (nonce) => ({
    type: actionTypes.GET_APPLICATION_WITH_NONCE,
    payload: nonce
});

export const getApplicationWithNonceSuccess = (data) => ({
    type: actionTypes.GET_APPLICATION_WITH_NONCE_SUCCESS,
    payload: data
});

export const getApplicationWithNonceFail = (error) => ({
    type: actionTypes.GET_APPLICATION_WITH_NONCE_FAIL,
    payload: error
});

/**
 * getUserDataById
 * @param {*} userId 
 */
export const getUserDataById = (userId) => ({
    type: actionTypes.GET_USER_DATA_BY_ID,
    payload: userId
});

export const getUserDataByIdSuccess = (data) => ({
    type: actionTypes.GET_USER_DATA_BY_ID_SUCCESS,
    payload: data
});

export const getUserDataByIdFail = (error) => ({
    type: actionTypes.GET_USER_DATA_BY_ID_FAIL,
    payload: error
});

/**
 * get the licensee user (golfers)
 */
export const getLicenseeUserDataById = (userId) => ({
    type: actionTypes.GET_LICENSEE_USER_DATA_BY_ID,
    payload: userId
});

export const getLicenseeUserDataByIdSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_USER_DATA_BY_ID_SUCCESS,
    payload: data
});

export const getLicenseeUserDataByIdFail = (error) => ({
    type: actionTypes.GET_LICENSEE_USER_DATA_BY_ID_FAIL,
    payload: error
});

export const getLicenseeUserDataByIdReset = () => ({
    type: actionTypes.GET_LICENSEE_USER_DATA_BY_ID_RESET
});


// export const getUsersByLicenseeId = (licenseeId) => ({
//     type: actionTypes.GET_USERS_BY_LICENSEE_ID,
//     payload: licenseeId
// });

// export const getUsersByLicenseeIdSuccess = (data) => ({
//     type: actionTypes.GET_USERS_BY_LICENSEE_ID_SUCCESS,
//     payload: data
// });

// export const getUsersByLicenseeIdFail = (error) => ({
//     type: actionTypes.GET_USERS_BY_LICENSEE_ID_FAIL,
//     payload: error
// });

// /**
//  * assignCreditToUser
//  */

export const assignCreditToUser = (userId, licenseeCampaignId) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER,
    payload: { userId, licenseeCampaignId }
});

export const assignCreditToUserSuccess = (data) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_SUCCESS,
    payload: data
});

export const assignCreditToUserFail = (error) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_FAIL,
    payload: error
});

export const assignCreditToUserReset = () => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_RESET
});

// /**
//  * inviteCreditForUser
//  */
//  export const inviteCreditForUser = (email, licenseeCampaignId, isPaid) => ({
//     type: actionTypes.INVITE_CREDIT_FOR_USER,
//     payload: { email, licenseeCampaignId, isPaid }
// });

// export const inviteCreditForUserSuccess = (data) => ({
//     type: actionTypes.INVITE_CREDIT_FOR_USER_SUCCESS,
//     payload: data
// });

// export const inviteCreditForUserFail = (error) => ({
//     type: actionTypes.INVITE_CREDIT_FOR_USER_FAIL,
//     payload: error
// });

// export const inviteCreditForUserReset = () => ({
//     type: actionTypes.INVITE_CREDIT_FOR_USER_RESET
// });
/**
 * updateUserProfile
 */
 export const updateUserProfile = (userId, firstName, lastName) => ({
    type: actionTypes.UPDATE_USER_PROFILE,
    payload: { userId, firstName, lastName }
});

export const updateUserProfileSuccess = (data) => ({
    type: actionTypes.UPDATE_USER_PROFILE_SUCCESS,
    payload: data
});

export const updateUserProfileFail = (error) => ({
    type: actionTypes.UPDATE_USER_PROFILE_FAIL,
    payload: error
});

export const updateUserProfileReset = () => ({
    type: actionTypes.UPDATE_USER_PROFILE_RESET
});


/**
 * getTargetLibrary
 */
 export const getTargetsForUser = (userId) => ({
    type: actionTypes.GET_TARGETS_FOR_USER,
    payload: { userId }
});

export const getTargetsForUserSuccess = (data) => ({
    type: actionTypes.GET_TARGETS_FOR_USER_SUCCESS,
    payload: data
});

export const getTargetsForUserFail = (error) => ({
    type: actionTypes.GET_TARGETS_FOR_USER_FAIL,
    payload: error
});
