import * as actionTypes from './types';

export const createTarget = (programId, userId, displayName, swingTypeId, textDefinition) => ({
    type: actionTypes.CREATE_TARGET,
    payload: { programId, userId, displayName, swingTypeId, textDefinition }
});

export const createTargetSuccess = (data) => ({
    type: actionTypes.CREATE_TARGET_SUCCESS,
    payload: data
});

export const createTargetFail = (error) => ({
    type: actionTypes.CREATE_TARGET_FAIL,
    payload: error
});

export const resetCreateTarget = () => ({
    type: actionTypes.RESET_CREATE_TARGET,
    payload: null
});

// /**
//  * getTargetLibrary
//  */
// export const getTargetsForUser = (userId) => ({
//     type: actionTypes.GET_TARGETS_FOR_USER,
//     payload: { userId }
// });

// export const getTargetsForUserSuccess = (data) => ({
//     type: actionTypes.GET_TARGETS_FOR_USER_SUCCESS,
//     payload: data
// });

// export const getTargetsForUserFail = (error) => ({
//     type: actionTypes.GET_TARGETS_FOR_USER_FAIL,
//     payload: error
// });

/**
 * getTargetLibrary
 */
export const getTargetLibrary = () => ({
    type: actionTypes.GET_TARGET_LIBRARY,
    payload: null
});

export const getTargetLibrarySuccess = (data) => ({
    type: actionTypes.GET_TARGET_LIBRARY_SUCCESS,
    payload: data
});

export const getTargetLibraryFail = (error) => ({
    type: actionTypes.GET_TARGET_LIBRARY_FAIL,
    payload: error
});

/**
 * add target from library to program
 */
export const addTargetFromLibrary = (targetId, programId, userId) => ({
    type: actionTypes.ADD_TARGET_FROM_LIBRARY,
    payload: { targetId, programId, userId }
});

export const addTargetFromLibrarySuccess = (data) => ({
    type: actionTypes.ADD_TARGET_FROM_LIBRARY_SUCCESS,
    payload: data
});

export const addTargetFromLibraryFail = (error) => ({
    type: actionTypes.ADD_TARGET_FROM_LIBRARY_FAIL,
    payload: error
});