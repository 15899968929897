import { swingbotApi } from '../../util/api';

export const loginUser = (email, password, sandbox) => {
    console.log('login ', sandbox);
    const path = sandbox === true ? '/account/login?debug=1' : 'account/login';
    return swingbotApi(path, { email, password }, 'post', true, 'account')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const registerLicensee = (email, password, applicationNonce) => {
    return swingbotApi('/account/register', { email, password, nonce: applicationNonce}, 'post', true, 'account')
        .then(response => response)
        .catch(err => {
            return Promise.reject(err);
        });
};

export const registerAndActivateLicensee = (firstName, lastName, email, password, nonce) => {
    return swingbotApi('/account/register-activate', { firstName, lastName, email, password, nonce }, 'post', true, 'account')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const activateLicensee = (nonce) => {
    return swingbotApi('/account/activate', { nonce }, 'post', true, 'account')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const forgotPassword = (email) => {
    return swingbotApi('/account/forgot-password', { email, site: 'pro' }, 'post', true, 'account')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const resetPassword = (password, confirmPassword, nonce) => {
    return swingbotApi('/account/reset-password', { password, confirmPassword, nonce }, 'post', true, 'account')
        .then(response => response)
        .catch(err => Promise.reject(err));
};