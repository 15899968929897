import * as actionTypes from './actionTypes';

/**
 * assignCreditToUser
 */

 export const assignCreditToUser = (userId, licenseeCampaignId) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER,
    payload: { userId, licenseeCampaignId }
});

export const assignCreditToUserSuccess = (data) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_SUCCESS,
    payload: data
});

export const assignCreditToUserFail = (error) => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_FAIL,
    payload: error
});

export const assignCreditToUserReset = () => ({
    type: actionTypes.ASSIGN_CREDIT_TO_USER_RESET
});

/**
 * inviteCreditForUser
 */
 export const inviteCreditForUser = (email, licenseeCampaignId, isPaid) => ({
    type: actionTypes.INVITE_CREDIT_FOR_USER,
    payload: { email, licenseeCampaignId, isPaid }
});

export const inviteCreditForUserSuccess = (data) => ({
    type: actionTypes.INVITE_CREDIT_FOR_USER_SUCCESS,
    payload: data
});

export const inviteCreditForUserFail = (error) => ({
    type: actionTypes.INVITE_CREDIT_FOR_USER_FAIL,
    payload: error
});

export const inviteCreditForUserReset = () => ({
    type: actionTypes.INVITE_CREDIT_FOR_USER_RESET
});